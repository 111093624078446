
@import "variables";
@import "bootstrap/scss/bootstrap";

.container {
  max-width: 960px;
}

html, body {
  scroll-padding-top: 100px; /* height of sticky header */
  scroll-behavior: smooth;
}
/*
 * Custom translucent site header
 */

.site-header {
  background-color: rgba(0, 0, 0, .85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.site-header a {
  color: #999;
  transition: ease-in-out color .15s;
}
.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

/*
 * Dummy devices (replace them with your own or something else entirely!)
 */



/*
 * Extra utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

.flex-equal > * {
  -ms-flex: 1;
  -webkit-box-flex: 1;
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
  }
}

.overflow-hidden { overflow: hidden; }

.jumbotron {
    margin-top: 70px;
    background-image: url('/assets/images/hero.jpg');
    background-repeat: repeat-x;
    background-size: contain;
    background-position: center;
    padding-bottom: 10rem;
    min-height: 400px;
   }

.hero_text{
      background: rgba(204, 204, 204, 0.65);

}
// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) { 
  .jumbotron {
  background-repeat: no-repeat;
  background-position: top;
  padding-bottom: 10rem;
  min-height: 300px;
    }  
 }
